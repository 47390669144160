import { useSiteNameforTC, useSiteURL } from "utility/countryOverride";
import { useProduct } from "hooks";

const SMSMarketingTermAndCondition = () => {

  let siteUrl = useSiteURL();
  if (useProduct() === 'ca') {
    siteUrl = 'https://www.leafhome.com';
  }
  const privacyURL =  'https://privacy.leafhome.com/';

  const siteName = useSiteNameforTC();
  return (
    <p className="text-slate-600 text-xs mt-3">By clicking Get Estimate, I consent to receive marketing prerecorded and automated calls
      from {`${siteName}`} at the phone number provided and agree to the <a href={`${siteUrl}/terms-of-use/`} target="_blank" rel="noreferrer"><u>Terms of use</u></a> and <a href={privacyURL} target="_blank" rel="noreferrer"><u>Privacy Policy</u></a>.
      Consent is not a condition of purchase.
    </p>
  );
}

export default SMSMarketingTermAndCondition;
