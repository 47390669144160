import React, { useContext, useRef, useState } from "react";
import { Form, Formik } from "formik";
import { Container, Box, Stack } from "@mui/material";
import EmailInput from "components/atoms/EmailInput";
import * as yup from "yup";
import PostService from "utility/postService";
import DataLayerService from "utility/dataLayerService";
import { PrimaryButtonNoTranistion, SecondaryButton } from "components/atoms/LeafButtons";
import { FormContext } from "App";
import Validation from "utility/validation";
import AlertMessage from "components/atoms/AlertMessage";
import { useReCaptchaScore } from "hooks";
import ReCAPTCHA from "react-google-recaptcha"

const SignUp = (props) => {
  const location = "sign-up-form";
  const [showThankYou, setShowThankYou] = useState(false);
  const title = props.dataSet?.title ? props.dataSet.title : "Sign Up to Receive Exclusive Discounts!";
  const description = props.dataSet?.description ? props.dataSet.description : "";
  const list = props.dataSet?.list ? props.dataSet.list : "lf_to_lhss_xs";
  const form = props.dataSet?.form ? props.dataSet.form : "modal";
  let errorMsg = "";
  let emailAddress = '';
  let captchaScore = null;
  const reCaptchaScoreConstant = useReCaptchaScore();
  const recaptchaRef = useRef();
  // Disabling recaptcha.
  const reCaptchaShow = process.env.PANTHEON_ENVIRONMENT === 'live' ? (process.env.REACT_APP_RECAPTCHA_SHOW_PROD === 'enable') : (process.env.REACT_APP_RECAPTCHA_SHOW_NON_PROD === 'enable');

  const formContext = useContext(FormContext);
  const ValidationSchema = yup.object().shape({
    emailAddress: yup
      .string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Email Address is not valid.')
      .required("Email Address is required.")
  });

  const handleOnFocus = () => {
    // Passing lf.form.start event to DataLayer.
    DataLayerService.formStartEvent('standard', 'sign-up-form');

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent('standard', 'sign-up-form');
  };

  const handleOnBlur = (event) => {
    switch (event.target.name) {
      case 'emailAddress':
        emailAddress = event.target.value;
        break;

      default:
        break;
    }

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const buttonSubmit = (e) => {
    const EmailValidation = Validation.EmailDataLayerValidation(emailAddress);

    // Validation Error.
    const validationError = {
      ...EmailValidation,
    };

    if (Object.keys(validationError).length !== 0) {
      DataLayerService.formFailEvent(form, location, validationError);
    }
  }

  const brand = formContext.brand;

  if (showThankYou) {
    return (
      <Container maxWidth={"sm"}>
        <Stack sx={{ width: '100%' }} spacing={2}>
          {brand === "lhws" ?
            <AlertMessage message={'Thanks for contacting us! We will get in touch with you shortly.'} severity={'success'} />
            :
            <AlertMessage message={'Thank you for your submission. Someone will be in touch with you shortly.'} severity={'success'} />
          }
        </Stack>
      </Container>
    )
  }
  else {
    return (
      <Formik
        initialValues={{
          emailAddress: '',
        }}
        validationSchema={ValidationSchema}
        onBlur={
          (event) => { handleOnBlur(event) }
        }
        onSubmit={async (values, actions) => {

          let submittedValues = {};
          let captchaValue = '';
          if (reCaptchaShow) {
            // Validate if the user is not a Robot.
            const RecaptchaVerification = await Validation.RecaptchaVerification();
            captchaScore = RecaptchaVerification.score;

            if (captchaScore < reCaptchaScoreConstant) {
              captchaValue = recaptchaRef.current.getValue();
            }
            else {
              captchaValue = RecaptchaVerification.token;
            }
         }

          if (captchaValue || !reCaptchaShow) {

            try {

              let submitEndPoint = ''
              if (brand === 'lhws') {
                submittedValues = {
                  'emailAddress': values.emailAddress,
                  "list_name": list,
                  'recaptcha_token': captchaValue,
                  'recaptcha_score': captchaScore,
                }
                submitEndPoint = 'lhws-signup';
              }
              else {
                submittedValues = {
                  'emailAddress': values.emailAddress,
                  "list_name": list,
                  'country': 'us',
                  'recaptcha_token': captchaValue,
                  'recaptcha_score': captchaScore,
                }
                submitEndPoint = 'sign-up';
              }
              const response = await PostService.postData(submitEndPoint, submittedValues, true);
              if (response.success) {
                setShowThankYou(true);
              }
              else {
                errorMsg = "Sorry, there was an error during submission. Please try again.";
                actions.setSubmitting(false);
              }

              // Passing lf.form.start event to DataLayer.
              DataLayerService.formSuccessEvent(form, location, submittedValues);
            }
            catch (error) {
              console.log("Error in sending data");
              console.log(error)
            }
          }
        }}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <Container maxWidth={"sx"} sx={{
            maxWidth: "550px",
            minHeight: "250px",
          }}
            className="signup-wrap">
            <h2 className="text-center">{title}</h2>
            {description ?
              <p className="text-center"> {description}</p>
              : ''}

            <Container maxWidth={"sm"} disableGutters={true}>
              <Form
                onSubmit={handleSubmit}
                onFocus={
                  () => { handleOnFocus() }
                }
                className="lhws-signup-form leaffilter-forms p-0"
              >
                {errorMsg ? (<AlertMessage message={errorMsg} severity={'error'} />) : ""}
                <Box display={"flex"} alignItems="center" sx={{
                  bgcolor: 'white',
                  padding: brand === 'lhws' ? "0px" : "2px",
                  width: brand === 'lhws' ? "100%" : "'380px'",
                  borderRadius: brand ? '50px' : '0px',
                  border: brand === 'lhws' ? '1px solid #fff' : 'none',

                }}
                >
                  <EmailInput
                    fieldName={'emailAddress'}
                    placeholder={'Email Address'}
                    label={" "}
                    style={{
                      "border": "none"
                    }}
                    boxStyle={{
                      "marginBottom": 0,
                      "flexGrow": "1",
                    }}
                  />
                  {brand === 'lhws' ?
                    <PrimaryButtonNoTranistion type="submit"
                      name="Sign Up"
                      handleClick={() => buttonSubmit()}
                      isSubmitting = {isSubmitting}
                      style={{
                        border: "2px solid white",
                        borderRadius: "50px",
                        fontSize: "15px",
                        fontWeight: "500"
                      }}
                    >
                      {isSubmitting ? ("Submitting") : ("Sign me Up")}</PrimaryButtonNoTranistion>
                    :
                    <SecondaryButton
                      type="submit"
                      handleClick={() => buttonSubmit()}
                      name="Sign Up"
                      isSubmitting = {isSubmitting}
                      style={{
                        border: "2px solid white",
                      }}
                    >
                      {isSubmitting ? ("Submitting") : ("Sign Up")}
                    </SecondaryButton>
                  }
                </Box>
                {reCaptchaShow && (
                  <div className="recaptcha">
                    {captchaScore !== null && captchaScore < reCaptchaScoreConstant && (
                      <>
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          size="normal"
                          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY_V2}
                        />
                        <p className="error-msgs">reCAPTCHA verification failed. Please try again.</p>
                      </>
                    )}
                  </div>
                )}

              </Form>
            </Container>
          </Container>
        )}
      </Formik>
    )
  }
}

export default SignUp;
