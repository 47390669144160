/*
 * This Service which will allow you to send information
 * from the Fromik form to the Datalayer.
*/

class DataLayerService {

  /*
   * Pass data to Datalayer.
  */
  async passDataToDataLayer(data) {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push(data);
  }

  /*
   * Passing lf.form.interaction to the DataLayer.
  */
  async formInteractionEvent(form, location) {
    // Form Interactions.
    const lhFormInteraction = {
      'event': 'lf.form.interaction',
      'details': {
        'name': "Generic",
        'location': location,
        'type': "field",
        'action': "focus",
        'form': form,
        'sourceEl': undefined,
      }
    };

    // Passing lf.form.interaction event to DataLayer.
    this.passDataToDataLayer(lhFormInteraction);
  }

  /*
   * Passing lf.form.start to the DataLayer.
  */
  async formStartEvent(form, location, data = '') {
    // Start of the Form.
    var lhStart = {
      'event': 'lf.form.start',
      'details': {
        'name': "Generic",
        'location': location,
        'type': "manual",
        'action': "focus",
        'form': form,
        'data': data,
      }
    };

    // Passing the lf.form.start event to Datalayer.
    this.passDataToDataLayer(lhStart);
  }

  /*
   * Passing lf.form.assessment to the DataLayer.
  */
  async formAssessmentEvent(data) {
    // Form Assessment.
    var lhFormAssessment = {
      'event': 'lf.form.assessment',
      'details': {
        'data': data,
      }
    };

    // Passing the lf.form.assessment event to Datalayer.
    this.passDataToDataLayer(lhFormAssessment);
  }

  /*
   * Passing lf.form.success to the DataLayer.
  */
  async formSuccessEvent(form, formType, location) {
    // Form Success.
    var lhFormSuccess = {
      'event': 'lf.form.success',
      'formType': formType,
      'formValue': null,
      details: {
        'name': "Generic",
        'location': location,
        'type': "field",
        'action': "focus",
        'form': form
      }
    };

    // Passing the lf.form.success event to Datalayer.
    this.passDataToDataLayer(lhFormSuccess);
  }

  /*
   * Passing lf.cta.interaction to the DataLayer.
  */
  async CTAInteractionEvent(name, location) {
    // CTA Interaction.
    var lhCTAInteraction = {
      'event': 'lf.cta.interaction',
      details: {
        'name': name,
        'location': location,
        'type': "modal",
        'action': "open"
      }
    };

    // Passing the lf.cta.interaction event to Datalayer.
    this.passDataToDataLayer(lhCTAInteraction);
  }

  /*
   * Passing lf.estimatemodal.impression to the DataLayer.
  */
  async modalImpressionEvent(location) {
    // Opening the Modal Window.
    var modalImpressionEvent = {
      'event': 'lf.estimatemodal.impression'
    };

    // Passing the lf.estimatemodal.impression event to Datalayer.
    this.passDataToDataLayer(modalImpressionEvent);
  }

  /*
   * Passing lf.form.fail to the DataLayer.
  */
  async formFailEvent(form, location, validation_error) {
    // Form fails.
    const lhFormFail = {
      'event': 'lf.form.fail',
      'details': {
        'name': "Generic",
        'location': location,
        'type': "form",
        'action': "submit",
        'form': form,
        'validation_errors': validation_error,
      }
    };

    // Passing lf.form.fail event to DataLayer.
    this.passDataToDataLayer(lhFormFail);
  }

  /*
   * Passing lf.form.status to the DataLayer.
  */
  async formStatusEvent(formName, subFormName, product, stepNumber, numberOfRequiredFields, status) {
    // Form Status event.
    const lhFormStatus = {
      'event': 'lf.form.status',
      'details': {
        'formName': formName,
        'subFormName': subFormName,
        'product': product,
        'stepNumber': stepNumber,
        'numberOfRequiredFields': numberOfRequiredFields,
        'status': status,
      }
    };

    // Passing lf.form.status event to DataLayer.
    this.passDataToDataLayer(lhFormStatus);
  }

  /*
   * Passing lf.form.zipcode to the DataLayer.
  */
  async zipCodeEventTracking(formName, subFormName, product, zipCode, status, validZipcode) {
    const lfFormZipCodeTracking = {
      'event': 'lf.form.zipcode',
      'details': {
        'formName': formName,
        'subFormName': subFormName,
        'product': product,
        'zipcode': zipCode,
        'status': status,
        'validZipcode': validZipcode,
      }
    }

    // Passing lf.form.zipcode event to DataLayer.
    this.passDataToDataLayer(lfFormZipCodeTracking);
  }

}

const dataLayerService = new DataLayerService();
export default dataLayerService;
