import {Form, Formik } from "formik";
import React, { useContext } from "react";
import { Container, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { WarrantyFormContext } from "./WarranyForm";
import { useGetPhoneNumber } from "utility/countryOverride";
import { TertiaryButton } from 'components/atoms/LeafButtons';
import LeafBox from "components/atoms/LeafBox";
import { ReactComponent as PhoneIcon } from "components/Forms/form-icons/phone-icon.svg"
import { useBrand } from "hooks";

const WarrantyAlreadyExists = () => {
  const phoneNumber = useGetPhoneNumber();
  const brand = useBrand();

  const { setActiveStep } = useContext(
    WarrantyFormContext
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let warrantyText = "Your LeafFilter job has already been registered.";

  if (brand === 'lhss') {
    warrantyText = "Your Leaf Home Safety Solutions project has already been registered."
  }
  else if (brand === 'lhws') {
    warrantyText = "Your Leaf Home Water Solutions project has already been registered."
  }

  return (
    <Formik
      initialValues={{

      }}
      onSubmit={async (values) => {
        setActiveStep('GetWarrantyDetails');
      }
    }
    >
      {({ handleSubmit }) => (
        <Container maxWidth={isMobile ? "xs" : "sm"} disableGutters={true}>
          <Form onSubmit={handleSubmit} className="text-center">
            <h1 className="fw-normal mb-4 h2">Existing Warranty Found</h1>
            <h2 className="h4 mb-4">{`${warrantyText}`}</h2>
            <LeafBox>
              <TertiaryButton type="submit">Go Back</TertiaryButton>
            </LeafBox>
            <LeafBox>
              <h3>If you require any assistance, our service team is happy to help:</h3>
            </LeafBox>
            <div className="h4 fw-normal">
              <PhoneIcon className="phone-icon"></PhoneIcon>
              {`${phoneNumber}`}
            </div>
          </Form>
        </Container>
      )}
    </Formik>
  );
}

export default WarrantyAlreadyExists;
