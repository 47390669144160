import { useVersion } from 'hooks';

export const PrimaryButton = ({ style = {}, children, handleClick, isSubmitting=false, ...props }) => {

  const v2 = useVersion();

  return (
    <button
      className={`btn btn-primary d-flex mx-auto justify-content-center ${v2 ? 'w-100' : ''}`}
      style={ style }
      onClick={ handleClick }
      disabled={isSubmitting ? "disabled": ""}
      {...props}
      >
      {children}
    </button>
  )
}

export const SecondaryButton = ({ style = {}, children, handleClick, isSubmitting=false, ...props }) => {
  const v2 = useVersion();

  return (
    <button
      className={`btn btn-secondary d-flex mx-auto justify-content-center ${v2 ? 'w-100' : ''}`}
      style={style}
      onClick={handleClick}
      disabled={isSubmitting ? "disabled": ""}
      {...props}
    >
      {children}
    </button>
  )
}

export const TertiaryButton = ({ style = {}, children, handleClick, isSubmitting=false, ...props }) => {
  return (
    <button
      className="btn btn-tertiary d-flex mx-auto py-2 justify-content-center"
      style={style}
      onClick={handleClick}
      disabled={isSubmitting ? "disabled": ""}
      {...props}
    >
      {children}
    </button>
  )
}

export const SecondaryPrimary = ({ style = {}, children, handleClick, isSubmitting=false, ...props }) => {
  return (
    <button
      className="btn btn-secondary-primary d-flex mx-auto py-2 justify-content-center"
      style={style}
      onClick={handleClick}
      disabled={isSubmitting ? "disabled": ""}
      {...props}
    >
      {children}
    </button>
  )
}

export const PrimaryButtonNoTranistion = ({ style = {}, children, handleClick, isSubmitting=false, ...props }) => {
  return (
    <button
      className="btn btn-primary d-flex mx-auto justify-content-center no-transition"
      style={ style }
      onClick={ handleClick }
      disabled={isSubmitting ? "disabled": ""}
      {...props}
      >
      {children}
    </button>
  )
}

export const LeafButton = ({ style = {}, children, handleClick, buttonClass, isSubmitting=false, ...props }) => {

  return (
    <button
      className={`btn ${buttonClass}`}
      style={ style }
      onClick={ handleClick }
      disabled={isSubmitting ? "disabled": ""}
      {...props}
      >
      {children}
    </button>
  )
}
