import { Field, Form, Formik } from "formik";
import React, { useContext, useRef } from "react";
import * as yup from "yup";
import { Container, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { FormContext } from "../../../../App";
import validation, { useZipCodeValidation } from "utility/validation";
import PostService from "utility/postService";
import TermAndCondition from "components/atoms/TermAndCondition";
import EmailInput from "components/atoms/EmailInput";
import PhoneNumberInput from "components/atoms/PhoneNumberInput";
import ZipCodeInput from "components/atoms/ZipcodeInput";
import TextInput from "components/atoms/TextInput";
import { PrimaryButton } from "components/atoms/LeafButtons";
import ReCAPTCHA from "react-google-recaptcha"
import { useReCaptchaScore } from "hooks";

const ReferRegistration = (props) => {

  // Zipcode Validation based on the Country Code.
  const zipCodeValidation = useZipCodeValidation();
  const reCaptchaScoreConstant = useReCaptchaScore();

  const { setActiveStepIndex, formData } = useContext(
    FormContext
  );
  let errorMsg = "";
  let captchaScore = null;
  // Disabling recaptcha.
  const reCaptchaShow = process.env.PANTHEON_ENVIRONMENT === 'live' ? (process.env.REACT_APP_RECAPTCHA_SHOW_PROD === 'enable') : (process.env.REACT_APP_RECAPTCHA_SHOW_NON_PROD === 'enable');

  const jobNumberValue = formData.jobNumber ? formData.jobNumber : '';
  const referrerFirstName = formData.firstName ? formData.first_name : '';
  const referrerLastName = formData.lastName ? formData.lastName : '';
  const referrerEmailAddress = formData.email ? formData.email : '';
  const referrerPhoneNumber = formData.phoneNumber ? formData.phone : '';

  const ValidationSchema = yup.object().shape({
    referralPostal: yup
      .string()
      .matches(zipCodeValidation.validationPattern, zipCodeValidation.validationMessage)
      .required(`${zipCodeValidation.zipCodeText} is required.`),
    referralPhone: yup
      .string()
      .matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Phone Number is not valid.")
      .required("Phone Number is required."),
    referralName: yup
      .string()
      .trim()
      .matches(/[^\s\d]+ .+$/, "Full Name must include both First Name and Last Name.")
      .required('Full Name is required.'),
    referralEmail: yup
      .string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Email Address is not valid.')
      .required("Email Address is required."),
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const recaptchaRef = useRef();

  return (
    <Formik
      initialValues={{
        emailAddress: referrerEmailAddress ? referrerEmailAddress : '',
        referralName: '',
        referralEmail: '',
        referralPhone: '',
        referralPostal: '',
        referralRelationship: '',
      }}
      validationSchema={ValidationSchema}
      onSubmit={async (values, actions) => {
        const [referralFirstName, referralLastName] = values.referralName.trim().split(' ');
        let captchaValue = '';

        if (reCaptchaShow) {
          const RecaptchaVerification = await validation.RecaptchaVerification();

          captchaScore = RecaptchaVerification.score;

          if (captchaScore < process.env.REACT_APP_RECAPTCHA_SCORE) {
            captchaValue = recaptchaRef.current.getValue();
          }
          else {
            captchaValue = RecaptchaVerification.token;
          }
       }

        if (captchaValue || !reCaptchaShow) {

          try {
            const args = {
              FirstName: referralFirstName,
              LastName: referralLastName,
              Email: values.referralEmail,
              Phone: values.referralPhone,
              Zip: values.referralPostal,
              ReferrerFirstName: referrerFirstName,
              ReferrerLastName: referrerLastName,
              ReferrerPhone: referrerPhoneNumber,
              ReferrerEmail: values.emailAddress,
              ReferrerJobNumber: jobNumberValue,
              Notes: (values.referralRelationship ? 'Relation: ' + values.referralRelationship : ''),
              recaptcha_token: captchaValue,
              recaptcha_score: captchaScore,
            };

            const loadData = await PostService.postData('save-customer-referral', args);
            if (loadData.success) {
              setActiveStepIndex('thankyou');
            }
            else {
              errorMsg = "Sorry, there was an error during submission. Please try again.";
              actions.setSubmitting(false);
            }
          } catch (error) {
            console.log(error);
            console.log("Error in sending data");
          }
        }
      }
      }
    >
      {({ handleSubmit, isSubmitting }) => (
        <Container maxWidth={isMobile ? "xs" : "sm"} disableGutters={true}>
          <Form
            className="flex flex-col justify-center items-start referral__container--fluid"
            onSubmit={handleSubmit}
          >
            {errorMsg ? (<div className="border border-danger border-1 p-2 mb-4 error-msg fw-normal">{errorMsg}</div>) : ""}
            <div className="flex flex-col justify-center items-start mb-4 field-wrapper field-result-wrapper">
              <div>
                <div>Name</div>
                <h4 className="mb-0 mt-7"> <strong>{referrerFirstName} {referrerLastName}</strong> </h4>
              </div>
              <div>
                <div>Job Number</div>
                <h4 className="mb-0 mt-7"> <strong>{jobNumberValue}</strong> </h4>
              </div>
            </div>

            <EmailInput fieldName={'emailAddress'} label={'Your Email'} />
            <h2 className="section-header pt-3 text-center"> Referral Details </h2>
            <p>Please enter the details of the person you wish to refer to LeafFilter and we'll send them an exclusive 10% discount! If they purchase LeafFilter, you will receive $50!</p>
            <br />

            <TextInput fieldName={'referralName'} label={'Full Name'} placeholder={"E.g., John Smith"} />
            <EmailInput fieldName={'referralEmail'} />
            <PhoneNumberInput fieldName={'referralPhone'} />
            <ZipCodeInput fieldName={'referralPostal'} />

            <div className="flex flex-col items-start mb-4 field-wrapper">
              <label>Relationship (Optional)</label>
              <Field name="referralRelationship" className={`input-field`} />
            </div>

            <p className="text-slate-600 text-xs">Tip: Your Job Number is located on the documentation left when the product was installed. An example of the kind of number you are looking for is:</p>
            {reCaptchaShow && (
              <div className="recaptcha">
                {captchaScore !== null && captchaScore < reCaptchaScoreConstant && (
                  <>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      size="normal"
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY_V2}
                    />
                    <p className="error-msgs">reCAPTCHA verification failed. Please try again.</p>
                  </>
                )}
              </div>
            )}

            <PrimaryButton type="submit" name="submit" isSubmitting={isSubmitting}>
              {isSubmitting ? 'Submitting' : 'Finish Registration'}
            </PrimaryButton>
            <TermAndCondition />
          </Form>
        </Container>
      )}
    </Formik>
  );
}

export default ReferRegistration;
