import { Form, Formik } from "formik";
import React, { useContext } from "react";
import { FormContext } from "../../../../App";
import * as yup from "yup";
import { Container, useTheme } from "@mui/material";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/system";
import { useMediaQuery } from "@mui/material";
import PostService from "utility/postService";
import TermAndCondition from "components/atoms/TermAndCondition";
import TextInput from "components/atoms/TextInput";
import LeafBox from 'components/atoms/LeafBox';
import Image from './referral/sample-job.jpg';
import { TertiaryButton } from "components/atoms/LeafButtons";
import JobNumberInput from "components/atoms/JobNumber";

const VerifyInstallation = (props) => {

  const StyledDivider = styled(Divider)`
    margin: 16px 0;
    width: 100%;
  `;
  const SampleJobImage = Image;
  let jobInfoError = false;

  const { setActiveStepIndex, formData, setFormData } = useContext(
    FormContext
  );

  const ValidationSchema = yup.object().shape({
    jobNumber: yup
      .string()
      .matches('^[a-zA-Z]{3}\\d+$', "Job Number is not valid.")
      .required("Please match the requested format."),
    lastName: yup.string().required("Last Name is required."),
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Formik
      initialValues={{
        jobNumber: "",
        lastName: "",
      }}
      validationSchema={ValidationSchema}
      onSubmit={async(values) => {
        const args = {
          JobNumber: values.jobNumber,
          LastName: values.lastName,
        };
        const jobinfo = await PostService.postData('get-job-info', args);

        const data = { ...formData, ...values, ...jobinfo.result};
        if (jobinfo.success) {
          setFormData(data);
          setActiveStepIndex('registration');
        }
        else {
          jobInfoError = true;
        }
      }
    }
    >
      {({ handleSubmit, isSubmitting }) => (
        <Container  maxWidth={isMobile ? "xs" : "sm"} disableGutters={true}>
          <Form
            className="flex flex-col justify-center items-start referral__container--fluid"
            onSubmit={handleSubmit}
          >

            <h2 className="section-header pt-3 text-center"> Verify Your Installation </h2>
            <div className="header-description centered"> Enter your job number below to verify your installation and refer your friends to LeafFilter. </div>
            <br/>
            {jobInfoError ?
            <p className="error-msg"> Installation could not be found, please verify the information provided and try again</p>
            :''
            }
            <TextInput fieldName={'lastName'} label={'Last Name'} placeholder={"E.g., Smith"} />
            <StyledDivider />
            <JobNumberInput fieldName={'jobNumber'} label={'Job Number'} />
            <LeafBox>
              <p className="text-slate-600 text-xs">Tip: Your Job Number is located on the documentation left when the product was installed. An example of the kind of number you are looking for is:</p>
              <img className="profile-photo" src={SampleJobImage} alt={"Graphic exemplifying a sample job number"}/>
            </LeafBox>

            <TertiaryButton type="submit" name="submit" isSubmitting={isSubmitting}>
              Verify Install
            </TertiaryButton>

            <TermAndCondition />
          </Form>
        </Container>
      )}
    </Formik>
  );
}

export default VerifyInstallation;
