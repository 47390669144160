import { FormControlLabel, Tooltip } from "@mui/material"
import { ReactComponent as QuestionMarkIcon } from "../../Forms/form-icons/questionmark.svg"

export default function ToolTipComponent () {
  const disclaimerStyle = {
    backgroundColor: '#00853e',
    fontSize: '10px',
    borderRadius: '50%',
    padding: '2px 5px',
    top: '-2px',
    position: 'relative',
    marginLeft: '10px',
  };
  const disclaimerLabelStyle = {
    color: '#00853e',
    fontWeight: '600',
    margin: '0',
  };
  const disclaimerIconStyle = {
    color: '#fff',
    display: 'inline-block',
    height: '1em',
    overflow: 'visible',
    verticalAlign: '-0.125',
  }
  return (
    <Tooltip title={<span>Please refer to our <a href="https://www.leaffilter.ca/promo/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a> for more details</span>} placement="top"
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "black",
            color: "white",
            width: "200px",
            fontSize: "12px",
            a: {
              color: "white"
            }
          },
        }
      }}>
      <span style={disclaimerStyle} className="disclaimer">
        <FormControlLabel
          style={disclaimerLabelStyle}
          value="questionmark"
          control={
            <QuestionMarkIcon
              className=""
              alt="terms of service"
              style={disclaimerIconStyle}
            />
          }
        />
      </span>
    </Tooltip>
  )
}
