import { Field } from "formik";
import React from "react";
import LeafBox from "components/atoms/LeafBox";
import { useSiteURL, useDNCPhoneNumber, useSiteName } from "utility/countryOverride";
import { useVersion } from 'hooks';

const SMSMarketingCampaign = ({SMSCampaignText}) => {

  const siteUrl = useSiteURL();
  const siteName = useSiteName();
  const privacyURL = 'https://privacy.leafhome.com/';
  let phoneNumber = useDNCPhoneNumber();
  const v2 = useVersion();

  const telPhoneNumber = phoneNumber.replace('-', '');

  const handleCallNow = () => {
    window.location.href = "tel:+" + telPhoneNumber;
  };

  return (
    <LeafBox>
      <Field type="checkbox" name="txtSMSMarketingConsent" id="txtSMSMarketingConsent"></Field>
      <label htmlFor="txtSMSMarketingConsent" className={"ms-1 position-cursor " + (v2 ? 'version-2-color' : '') }>
        Text me reminders and updates
      </label>

      <p className='mt-2'>
        By providing your phone number above and checking this box,
        you agree to receive automated text messages from {`${siteName}`}{" "}
        and also agree to our <a href={`${siteUrl}/terms-of-use/`} target="_blank" rel="noreferrer"><u>Terms of use</u></a>{" "}
        and <a href={privacyURL} target="_blank" rel="noreferrer"><u>Privacy Policy</u></a>.
        Consent is not a condition of purchase, Message frequency will vary.
        Message and data rates may apply. Unsubscribe to text messages at any time by replying STOP or calling us at {" "}
        <span className="phone-number" onClick={handleCallNow}>{`${phoneNumber}`}</span>.
        For questions or assistance reply HELP.
      </p>

    </LeafBox>
  )
}

export default SMSMarketingCampaign;
