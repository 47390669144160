import { ErrorMessage } from 'formik';
import React from "react";
import { ReactComponent as SuccessIcon } from "../../Forms/form-icons/success.svg";
import { ReactComponent as ErrorIcon } from "../../Forms/form-icons/error.svg";
import { useVersion } from 'hooks';

const ValidationIcon = ({fieldName, touched, errors}) => {
  const v2 = useVersion();
  const errorStyle = {
    color: (`${v2 ? '#c02b0a' : '#c900000'}`),
    fontWeight: '600',
    marginBottom: '0',
  };
  const successIconStyle = {
    color: '#00853e',
    marginLeft: '-30px',
    width: '15px',
    height: '15px',
  };
  const errorIconStyle = {
    color: 'red',
    marginLeft: '-30px',
    width: '15px',
    height: '15px',
  };
  const renderError = (message) => <p className="error-msg" style={errorStyle}>{message}</p>;

  return (
    <>
      {!v2 && touched[fieldName] && !errors[fieldName] && (
        <SuccessIcon style={successIconStyle} className="validation-icon success-icon"></SuccessIcon>
      )}
      {!v2 && touched[fieldName] && errors[fieldName] && (
        <ErrorIcon style={errorIconStyle} className="validation-icon error-icon"></ErrorIcon>
      )}
      <ErrorMessage name={fieldName} render={renderError} />
    </>
  );
}

export default ValidationIcon;
