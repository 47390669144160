import { Field, Form, Formik } from "formik";
import React, { useContext, useRef } from "react";
import {
  Container,
  FormControlLabel,
  FormControl,
  Typography,
  useTheme,
  Paper
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import Divider from "@mui/material/Divider";
import DataLayerService from "utility/dataLayerService";
import LeafBox from "components/atoms/LeafBox";
import { useComponentName } from "hooks";

// Import SVG Icons.
import { ReactComponent as ImmediatelyIcon } from "components/Forms/form-icons/immediately_15955c463d.svg";
import { ReactComponent as WeeksIcon } from "components/Forms/form-icons/1-2-weeks_15951f4c50.svg";
import { ReactComponent as FlexibleIcon} from "components/Forms/form-icons/flexible_15954a42aa.svg";
import { ReactComponent as YesIcon } from "components/Forms/form-icons/yes_15968c97c1.svg";
import { ReactComponent as NoIcon } from "components/Forms/form-icons/no_15958a5221.svg";
import { FormContextLHWSMultiStepEstimate } from "./LHWSMultiStepEstimate";
import styled from "@emotion/styled";
import TermAndCondition from "components/atoms/TermAndCondition";
import { PrimaryButton } from "components/atoms/LeafButtons";
import ProgressBar from "components/atoms/ProgressBar";
import { useVersion } from "hooks";
import ProgressBarWithPercentage from 'components/atoms/ProgressBarWithPercentage';
import { scrollToTop } from '../../../../utility/functions';

const ProjectDetailsStep = (props) => {
  const location = props.dataSet?.location ? props.dataSet.location : "multi-step-modal-form";
  const form = props.dataSet?.form ? props.dataSet.form : "modal";
  const { setactiveLHWSStepIndex, formData, setFormData } = useContext(FormContextLHWSMultiStepEstimate);
  const product = 'lhws';
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || 'ZipChecker';
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);
  const v2 = useVersion();
  const formRef = useRef(null);

  const handleProjectTimeClick = (value, setFieldValue) => {
    setFieldValue("projectTime", value);

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const handleFinancingClick = (value, setFieldValue) => {
    setFieldValue("financing", value);

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const StyledFormContainer = styled(Paper)(() => ({
    background: "#ffffff",
    borderRadius: 0,
    maxWidth: isMobile ? "100%" : 520,
    width: "100%",
    height: isMobile ? "auto" : 720,
  }));

  return (
    <Formik
      initialValues={{
        homeOwner: "",
        financing: "",
      }}

      onSubmit={async (values) => {
        const data = { ...formData, ...values };
        setFormData(data);
        setactiveLHWSStepIndex('final');
        if (v2) {
          scrollToTop(formRef);
        }

        if (userJourneyTracking) {
          let stepNumber = 4;
          if ((componentName === 'ZipChecker') || (componentName === 'ModalForm')) {
            stepNumber = 5;
          }
          // Passing lf.form.status event to DataLayer.
          DataLayerService.formStatusEvent(formName, subformName, product, stepNumber, 4, 'notcompleted');
        }

        // Passing lf.form.start event to DataLayer.
        DataLayerService.formStartEvent(form, location, data);

      }}
    >
      {({ handleSubmit, setFieldValue, values, touched, errors, isSubmitting }) => (
        <Container maxWidth={isMobile ? "xs" : "sm"} disableGutters={true}>
          <StyledFormContainer>
            <Form
              className="flex flex-col justify-center items-start"
              ref={formRef}
              onSubmit={handleSubmit}
            >
              <h3 className="form-step-title text-2xl fw-700">
              You're almost there! Just a few more steps.
              </h3>
              <FormControl component="fieldset">
                <label>When are you looking to start this project?</label>
                <div>
                  <Field name="roleType">
                    {({ field }) => (
                      <FormControl component="fieldset">
                        <div className="image-wrapper">
                          <FormControlLabel
                            className={`lf-icon-control-label immediately ${
                              values.projectTime === 'immediately'
                                ? "selected-border"
                                : ""
                            }`}
                            value="immediately"
                            control={
                              <ImmediatelyIcon
                                className={`custom-img ${
                                  values.projectTime === "immediately"
                                    ? "selected"
                                    : ""
                                }`}
                                alt="immediately"
                                onClick={() =>
                                  handleProjectTimeClick(
                                    "immediately",
                                    setFieldValue
                                  )
                                }
                              />
                            }
                            label={
                              <Typography
                                variant="body4"
                                className={`image-wrapper-label-text ${
                                  values.projectTime === "immediately"
                                    ? "selected"
                                    : ""
                                }`}
                              >
                                Immediately
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            className={`lf-icon-control-label Weeks ${
                              values.projectTime === '1-2 Weeks'
                                ? "selected-border"
                                : ""
                            }`}
                            value="1-2 Weeks"
                            control={
                              <WeeksIcon
                                className={`custom-img ${
                                  values.projectTime === "1-2 Weeks"
                                    ? "selected"
                                    : ""
                                }`}
                                alt="1-2 Weeks"
                                onClick={() =>
                                  handleProjectTimeClick(
                                    "1-2 Weeks",
                                    setFieldValue
                                  )
                                }
                              />
                            }
                            label={
                              <Typography
                                variant="body4"
                                className={`image-wrapper-label-text ${
                                  values.projectTime === "1-2 Weeks"
                                    ? "selected"
                                    : ""
                                }`}
                              >
                               1-2 Weeks
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            className={`lf-icon-control-label Flexible ${
                              values.projectTime === 'Flexible'
                                ? "selected-border"
                                : ""
                            }`}
                            value="flexible"
                            control={
                              <FlexibleIcon
                                className={`custom-img ${
                                  values.projectTime === "Flexible"
                                    ? "selected"
                                    : ""
                                }`}
                                alt="flexible"
                                onClick={() =>
                                  handleProjectTimeClick(
                                    "Flexible",
                                    setFieldValue
                                  )
                                }
                              />
                            }
                            label={
                              <Typography
                                variant="body4"
                                className={`image-wrapper-label-text ${
                                  values.projectTime === "Flexible"
                                    ? "selected"
                                    : ""
                                }`}
                              >
                                Flexible
                              </Typography>
                            }
                          />
                        </div>
                      </FormControl>
                    )}
                  </Field>
                </div>
              </FormControl>
              <Divider className="divider" />

              <FormControl component="fieldset">
                <label>Are you interested in financing?</label>
                <div>
                  <Field name="financing">
                    {({ field }) => (
                      <FormControl component="fieldset">
                        <div className="image-wrapper">
                          <FormControlLabel
                           className={`lf-icon-control-label Yes ${
                            values.financing === "yes"
                              ? "selected-border"
                              : ""
                          }`}
                            value="yes"
                            control={
                              <YesIcon
                                className={`custom-img ${
                                  values.financing === "yes"
                                    ? "selected"
                                    : ""
                                }`}
                                alt="yes"
                                onClick={() =>
                                  handleFinancingClick(
                                    "yes",
                                    setFieldValue
                                  )
                                }
                              />
                            }
                            label={
                              <Typography
                                variant="body4"
                                className={`image-wrapper-label-text ${
                                  values.financing === "yes"
                                    ? "selected"
                                    : ""
                                }`}
                              >
                                Yes
                              </Typography>
                            }
                          />
                          <FormControlLabel
                           className={`lf-icon-control-label No ${
                            values.financing === "no"
                              ? "selected-border"
                              : ""
                          }`}
                            value="no"
                            control={
                              <NoIcon
                                className={`custom-img ${
                                  values.financing === "no"
                                    ? "selected"
                                    : ""
                                }`}
                                alt="familyMember"
                                onClick={() =>
                                  handleFinancingClick(
                                    "no",
                                    setFieldValue
                                  )
                                }
                              />
                            }
                            label={
                              <Typography
                                variant="body4"
                                className={`image-wrapper-label-text ${
                                  values.financing === "no"
                                    ? "selected"
                                    : ""
                                }`}
                              >
                                No
                              </Typography>
                            }
                          />
                        </div>
                      </FormControl>
                    )}
                  </Field>
                </div>
              </FormControl>
              <Divider className="divider" />

              <LeafBox style={{
                marginTop: 2,
                marginBottom: 3,
              }}>
                <PrimaryButton
                  type="submit"
                  isSubmitting={isSubmitting}>
                  Next
                </PrimaryButton>
              </LeafBox>
              { v2 ?
                <ProgressBarWithPercentage />
                :
                <ProgressBar customClass="" />
              }
              <TermAndCondition />
            </Form>
            </StyledFormContainer>
        </Container>
      )}
    </Formik>
  );
}

export default ProjectDetailsStep;
