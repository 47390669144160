
import { useProduct } from "hooks";
import React, { useContext } from "react";
import { FormContextLHSSMultiStepEstimate } from "components/Forms/LHSS/LHSS-MultiStep-Estimate/LHSSMultiStepEstimate";
import { FormContextLHWSMultiStepEstimate } from "components/Forms/LHWS/LHWSMultiStepEstimate/LHWSMultiStepEstimate";
import { FormContext } from "App";

const ProgressBar = (props) => {

  const { activeStepIndex } = useContext(FormContext);
  const { activeLHSSStepIndex } = useContext(FormContextLHSSMultiStepEstimate);
  const { activeLHWSStepIndex } = useContext(FormContextLHWSMultiStepEstimate);

  const customClass1 = "success";
  const customClass2 = "incomplete"
  let  progressIndex = 0;
  const product = useProduct();
  let backgroundColor = '#00853e';


  const lhssProgressSteps = [
    'group',
    'details',
    'project',
    'final'
  ];

  const progressSteps = [
   1,
   2,
   3,
   4
  ];

  switch(product) {

    case 'lhss':
      progressIndex  = lhssProgressSteps.indexOf(activeLHSSStepIndex);
      backgroundColor = '#3d7491';
      break;
    case 'lhws':
      progressIndex  = lhssProgressSteps.indexOf(activeLHWSStepIndex);
      backgroundColor = '#1e7ac4';
      break;
    default:
      progressIndex = progressSteps.indexOf(activeStepIndex);
      break;

  }

  return (
    <div className="mx-auto">
      <div className="d-flex py-4 justify-content-center">
        <div className="progress" style={{ width: "50%"}}>
          <div
            className={`progress-bar mr-2 ${
              progressIndex >= -1 ? customClass1 : customClass2
            }`}
            role="progressbar"
            style={{ width: "100%", backgroundColor: `${
              customClass1 === 'success' ? backgroundColor : ''
            }`}}
          ></div>
        </div>
        <div className="spacer"></div>

        <div className="progress" style={{ width: "50%" }}>
          <div
            className={`progress-bar ${
              progressIndex >= 0 ? customClass1 : customClass2
            }`}
            role="progressbar"
            style={{ width: "100%", backgroundColor: `${
              customClass1 === 'success' ? backgroundColor : ''
            }`}}
          ></div>
        </div>
        <div className="spacer"></div>
        <div className="progress" style={{ width: "50%" }}>
          <div
            className={`progress-bar ${
              progressIndex >= 1 ? customClass1 : customClass2
            }`}
            role="progressbar"
            style={{ width: "100%", backgroundColor: `${
              customClass1 === 'success' ? backgroundColor : ''
            }`}}
          ></div>
        </div>
        <div className="spacer"></div>
        <div className="progress" style={{ width: "50%" }}>
          <div
            className={`progress-bar ${
              progressIndex >= 2 ? customClass1 : customClass2
            }`}
            role="progressbar"
            style={{ width: "100%", backgroundColor: `${
              customClass1 === 'success' ? backgroundColor : ''
            }`}}
          ></div>
        </div>
        <div className="spacer"></div>
        <div className="progress" style={{ width: "50%" }}>
          <div
            className={`progress-bar ${
              progressIndex >= 3 ? customClass1 : customClass2
            }`}
            role="progressbar"
            style={{ width: "100%", backgroundColor: `${
              customClass1 === 'success' ? backgroundColor : ''
            }`}}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default ProgressBar;