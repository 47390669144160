import { Form, Formik } from "formik";
import React, { useContext } from "react";
import { Container } from "@mui/material";
import { WarrantyFormContext } from "./WarranyForm";
import * as yup from "yup";
import PostService from "utility/postService";
import TermAndCondition from "components/atoms/TermAndCondition";
import TextInput from "components/atoms/TextInput";
import LeafBox from 'components/atoms/LeafBox';
import { SecondaryButton, TertiaryButton } from 'components/atoms/LeafButtons';
import LHSSJobImage from './images/lhss-warranty.jpg';
import LHWSJobImage from './images/lhws-warranty.jpeg';
import DefaultJobImage from './images/sample-job.jpg';
import JobNumberInput from "components/atoms/JobNumber";
import { useBrand } from 'hooks';

const GetWarrantyDetails = (props) => {

  const { setActiveStep, formData, setFormData } = useContext(
    WarrantyFormContext
  );

  const brand = useBrand();

  let warrantyValidationPattern = '';
  let jobFilePath = '';
  let errorMsg = '';
  const warrantyHelpText = 'Tip: Your Job Number is located on the documentation left when the product was installed.';

  if ( brand === 'lhss' ) {
    warrantyValidationPattern = '^[a-zA-Z]{4}\\d+$'
    jobFilePath = LHSSJobImage;

  }
  else if (brand === 'lhws' ) {
    warrantyValidationPattern = '^[a-zA-Z]{4}\\d+$'
    jobFilePath = LHWSJobImage;
  }
  else {
    warrantyValidationPattern = '^[a-zA-Z]{3}\\d+$';
    jobFilePath = DefaultJobImage;
  }

  const ValidationSchema = yup.object().shape({
    jobNumber: yup
      .string()
      .trim()
      .matches(warrantyValidationPattern, "Job Number is not valid.")
      .required("Please match the requested format."),
    lastName: yup.string().required("Last Name is required."),
  });

  return (
    <Formik
      initialValues={{
        lastName: "",
        jobNumber: "",
      }}
      validationSchema={ValidationSchema}
      onSubmit={async (values, actions) => {

        const args = {
          JobNumber: values.jobNumber,
          LastName: values.lastName,
          brand: brand
        };
        const jobinfo = await PostService.postData('get-job-info', args);

        let data = { ...formData, ...values };
        actions.setSubmitting(true);
        if (jobinfo.success) {
          data = { ...data, ...jobinfo.result }
        }

        if (!jobinfo.success) {
          setActiveStep('WarrantyDoesNotExist');
        }
        if (data.hasOwnProperty('warranty_registered')) {

          switch (data.warranty_registered) {
            case 'true':
              setActiveStep('WarrantyAlreadyExists');
              break;
            case 'false':
              setFormData(data);
              setActiveStep('RegisterWarranty');
              break;
            default:
              setActiveStep();
          }
        }
        else {
          errorMsg = "Sorry, there was an error during submission. Please try again.";
          actions.setSubmitting(false);
         }
       }

      }
    >
      {({ handleSubmit, isSubmitting }) => (
        <Container maxWidth={"sm"} disableGutters={true} sx={{
          maxWidth: "500px"
        }}>
          <Form onSubmit={handleSubmit}>
            <LeafBox>
              <h2 className="text-center py-5 fw-normal"> Register your Warranty </h2>
            </LeafBox>
            {errorMsg ? (<div className="border border-danger border-1 p-2 mb-4 error-msg fw-normal">{errorMsg}</div>) : ""}
            <TextInput
              fieldName={'lastName'}
              label={'Last Name'}
              placeholder={"E.g., Smith"}
            />
            <JobNumberInput fieldName={'jobNumber'} label={'Job Number'} />
            <LeafBox>
              <img className="profile-photo" src={jobFilePath} alt={"Graphic exemplifying a sample job number"} />
            </LeafBox>

            <LeafBox><p className="text-slate-600 text-xs">{warrantyHelpText} </p></LeafBox>

            <LeafBox className="warranty-btn-wrap">
              { brand === 'lhws' || brand === 'lhss' ?
                <SecondaryButton type="submit" isSubmitting={isSubmitting}>
                  {isSubmitting ? ("Finding Details") : ("Find Install")}
                </SecondaryButton>
              :
                <TertiaryButton type="submit" isSubmitting={isSubmitting}>
                  {isSubmitting ? ("Finding Details") : ("Find Install")}
                </TertiaryButton>
              }
            </LeafBox>
            <TermAndCondition />
          </Form>
        </Container>
      )}
    </Formik>
  );
}

export default GetWarrantyDetails;
