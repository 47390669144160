import { Form, Formik } from "formik";
import React, { useContext, useRef } from "react";
import * as yup from "yup";
import { Container } from "@mui/material";
import { WarrantyFormContext } from "./WarranyForm";
import PostService from "utility/postService";
import TermAndCondition from "components/atoms/TermAndCondition";
import EmailInput from "components/atoms/EmailInput";
import PhoneNumberInput from "components/atoms/PhoneNumberInput";
import TextInput from "components/atoms/TextInput";
import JobNumberInput from "components/atoms/JobNumber";
import { useBrand, useProduct } from 'hooks';
import { PrimaryButton, SecondaryButton } from "components/atoms/LeafButtons";
import ReCAPTCHA from "react-google-recaptcha"
import validation from "utility/validation";
import { useReCaptchaScore } from "hooks";

// Import SVG Icons.

const RegisterWarranty = (props) => {

  const brand = useBrand();
  const product = useProduct();
  const { setActiveStep, formData } = useContext(
    WarrantyFormContext
  );
  let errorMsg = "";
  let captchaScore = null;

  // Disabling recaptcha.
  const reCaptchaShow = process.env.PANTHEON_ENVIRONMENT === 'live' ? (process.env.REACT_APP_RECAPTCHA_SHOW_PROD === 'enable') : (process.env.REACT_APP_RECAPTCHA_SHOW_NON_PROD === 'enable');

  const recaptchaRef = useRef();
  const reCaptchaScoreConstant = useReCaptchaScore()

  let warrantyValidationPattern = '';
  if ( brand === 'lhss' ) {
    warrantyValidationPattern = '^[a-zA-Z]{4}\\d+$'
  }
  else if ( brand === 'lhws' ) {
    warrantyValidationPattern = '^[a-zA-Z]{4}\\d+$'
  }
  else {
    warrantyValidationPattern = '^[a-zA-Z]{3}\\d+$';
  }

  const ValidationSchema = yup.object().shape({
    jobNumber: yup
      .string()
      .matches(warrantyValidationPattern, "Job Number is not valid.")
      .required("Please match the requested format."),
    fullName: yup
      .string()
      .trim()
      .matches(/[^\s\d]+ .+$/, "Full Name must include both First Name and Last Name.")
      .required('Full Name is required.'),
    emailAddress: yup
      .string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Email Address is not valid.')
      .required("Email Address is required."),
  });

  return (
    <Formik
      initialValues={{
        fullName: (formData.firstName || formData.first_name || '') + ' ' + (formData.lastName || formData.last_name || ''),
        emailAddress: formData.email || '',
        jobNumber: formData.jobNumber || formData.job_number || '',
        address: formData.address || '',
        phoneNumber: formData.phone || '',
      }}
      validationSchema={ValidationSchema}
      onSubmit={async (values, actions) => {
        const [firstName, lastName] = values.fullName.trim().split(' ');
        let captchaValue = '';

        if (reCaptchaShow) {
          const RecaptchaVerification = await validation.RecaptchaVerification();
          captchaScore = RecaptchaVerification.score;
          if (captchaScore < process.env.REACT_APP_RECAPTCHA_SCORE) {
            captchaValue = recaptchaRef.current.getValue();
          }
          else {
            captchaValue = RecaptchaVerification.token;
          }
       }

        if (captchaValue || !reCaptchaShow) {
            try {
              const args = {
                jobNumber: values.jobNumber,
                LastName: lastName,
                FirstName: firstName,
                Email: values.emailAddress,
                Phone: values.phoneNumber,
                brand: brand,
                product: product,
                recaptcha_token : captchaValue,
                recaptcha_score: captchaScore,
              };

              const loadData = await PostService.postData('register-warranty', args);
              if (loadData.success) {
                setActiveStep('ThankYou');
              }
              else {
                errorMsg = "Sorry, there was an error during submission. Please try again.";
                actions.setSubmitting(false);
              }
            } catch (error) {
              console.log(error);
              console.log("Error in sending data");
            }
        }
      }
    }
    >
      {({ handleSubmit, isSubmitting }) => (
        <Container maxWidth="sm" disableGutters={true}>
          <Form
            className="flex flex-col justify-center items-start referral__container--fluid"
            onSubmit={handleSubmit}
          >
            <h1 className="section-header centered h2"> Please verify your installation information </h1>
            {errorMsg ? (<div className="border border-danger border-1 p-2 mb-4 error-msg fw-normal">{errorMsg}</div>) : "" }
            <div className="flex flex-col items-start mb-4 field-wrapper">
              <TextInput fieldName={'fullName'} label={'Full Name'} placeholder={"E.g., John Smith"} />
            </div>

            <div className="flex flex-col items-start mb-4 field-wrapper">
              <JobNumberInput fieldName={'jobNumber'} label={'Job Number'} />
            </div>
            <div className="flex flex-col items-start mb-4 field-wrapper">
              <TextInput fieldName={'address'} label={'Address'} placeholder={'Address'} />
            </div>
            <div className="flex flex-col items-start mb-4 field-wrapper">
              <PhoneNumberInput fieldName={'phoneNumber'} />
            </div>
            <div className="flex flex-col items-start mb-4 field-wrapper">
              <EmailInput fieldName={'emailAddress'} />
            </div>

            {reCaptchaShow && (
              <div className="recaptcha">
                {captchaScore !== null && captchaScore < reCaptchaScoreConstant && (
                  <>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      size="normal"
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY_V2}
                    />
                    <p className="error-msgs">reCAPTCHA verification failed. Please try again.</p>
                  </>
                )}
              </div>
            )}

            <div className="buttons">
              <span>
              { brand === 'lhws' ?
                <SecondaryButton type="submit" isSubmitting={isSubmitting}>
                 {isSubmitting ? 'Verifiying Install':  'Verify Install' }
                </SecondaryButton>
              :
                <PrimaryButton type="submit" isSubmitting={isSubmitting}>
                  {isSubmitting ? 'Verifiying Install':  'Verify Install' }
                </PrimaryButton>
              }
              </span>
            </div>
            <TermAndCondition />
          </Form>
        </Container>
      )}
    </Formik>
  );
}

export default RegisterWarranty;
