import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useContext, useRef } from "react";
import { FormContext } from "../../../App";
import { Container, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import BasicModal from "../Modal-Form/ModalForm";
import "./style.scss";
import DataLayerService from "utility/dataLayerService";
import { useZipCodeValidation } from "utility/validation";
import PostService from "utility/postService";
import { useCountry, useProduct, useComponentName } from "hooks";
import { PrimaryButton } from "components/atoms/LeafButtons";
import TrustedFormScript from "components/atoms/TrustedFormScript";
import debounce from "lodash/debounce";

const ZipChecker = (props) => {

  const zipCheckerComponentid = props.zipCheckerComponent;
  const zipSource = props.dataSet?.zipsource || "localzip";
  const country = useCountry();
  const product = useProduct();
  let zipCode = '';
  let zipcodeInputValue = '';
  let serviceAreaFlag = false;
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || 'ZipChecker';
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);
  let zipCodeValidationStatus = 'invalid';
  const zipCodeRef = useRef(null);
  const { formData, setFormData } =
    useContext(FormContext);

  // Zipcode Validation based on the Country Code.
  const zipCodeValidation = useZipCodeValidation();

  // Validation for the Zipcode.
  const validate = async (values) => {
    if (!values.zipCode) {
      return {
        'zipCode': zipCodeValidation.zipCodeText + ' is required.'
      }
    }

    if (!zipCodeValidation.validationPattern.test(values.zipCode)) {
      return {
        zipCode: zipCodeValidation.validationMessage,
      }
    }

    if ((zipcodeInputValue !== values.zipCode) && (zipCodeValidation.validationPattern.test(values.zipCode))) {
      serviceAreaFlag = await PostService.checkAvailableService(values.zipCode, product, zipSource);
      zipcodeInputValue = values.zipCode;
      zipCodeValidationStatus = (serviceAreaFlag === true) ? 'valid' : 'invalid';
      if (userJourneyTracking) {
        console.log("User Journey");
        DataLayerService.zipCodeEventTracking(formName, subformName, product, values.zipCode, 'completed', zipCodeValidationStatus);
      }
    }

    if (!serviceAreaFlag) {
      return {
        zipCode: zipCodeValidation.restrictedValidationMessage,
      }
    }
  };

  // Set the Zipcode value on Blur.
  const handleOnBlur = (event) => {
    zipCode = event.target.value;
  };

  const formSubmitHandler = async (values) => {
    const data = { ...formData, ...values };
    if ((zipCode) || (values.zipCode)) {
      setFormData(data);

      // Start of the Form.
      var lhStart = {
        'event': 'lf.form.start',
        'details': {
          'name': "Generic",
          'location': "hero",
          'type': "form",
          'action': "open",
          'form': "modal",
          'data': data,
        }
      };

      // Passing the lf.form.start event to Datalayer.
      DataLayerService.passDataToDataLayer(lhStart);

      const modalButton = document.getElementById("zip-checker-modal-id");
      modalButton.click();

      // Passing the lf.estimatemodal.impression event to Datalayer.
      DataLayerService.modalImpressionEvent();
    }
  };

  const renderError = (message) => <p className="error-msgs">{message}</p>;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const onSubmit = debounce(formSubmitHandler, 100, {
    leading: true,
    trailing: false
  });

  return (
    <Formik
      initialValues={{
        zipCode: "",
      }}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, touched, errors, isSubmitting }) => (
        <div className="zipchecker-form">
          <Container disableGutters maxWidth={isMobile ? "xs" : "sm"}>
            <Form
              onSubmit={handleSubmit}
              onBlur={
                (event) => { handleOnBlur(event) }
              }
            >
              <div className="position-relative">
                <div className="field-container">
                  <Field
                    innerRef={zipCodeRef}
                    className={`input-field ${touched.zipCode && errors.zipCode
                        ? "error"
                        : touched.zipCode
                          ? "correct"
                          : ""
                      }`}
                    name="zipCode"
                    placeholder={zipCodeValidation.zipCodePlaceHolder}
                  />

                  <PrimaryButton
                    isSubmitting = {isSubmitting}
                    style={{
                      border: "#ef643d",
                      position: "absolute",
                      right: "2px",
                      top: "2px",
                      height: "40px",
                      padding: "7px 10px",
                      borderRadius: "3px",
                      fontSize: "16px",
                      lineHeight: "24px",
                      margin: "0 auto"
                    }}
                    type="submit">Get Estimate
                  </PrimaryButton>
                  <BasicModal
                    modalComponent={zipCheckerComponentid}
                    country={country}
                    zipCheckerModalId="zip-checker-modal-id"
                    extraClasses={["d-none"]}
                    zipCode={formData.zipCode}
                    dataSet ={props.dataSet}
                  />
                  <ErrorMessage name="zipCode" render={renderError} />
                </div>
              </div>
              <TrustedFormScript />
            </Form>
          </Container>
        </div>
      )}
    </Formik>
  );
}

export default ZipChecker;
