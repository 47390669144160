import { createContext, useState, useEffect } from "react";
import componentMap from "./utility/utility";
import ReactRecaptcha3 from 'react-google-recaptcha3';
export const FormContext = createContext();

function App(props) {
  const zipCode = localStorage.getItem("zipCode");
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [recaptchaScore, setRecaptchaScore] = useState();
  const [formData, setFormData] = useState({ zipCode: zipCode });
  const countryProp = props.dataSet?.country ? props.dataSet.country : "";
  const [country, setCountry] = useState(countryProp);
  const brandProp = props.dataSet?.brand ? props.dataSet.brand : "";
  const v2Prop = props.dataSet?.version ? true : false;
  const [v2, setV2] = useState(v2Prop);
  const [brand, setBrand] = useState(brandProp);
  const Component = componentMap(props.component);

    useEffect(() => {
      const reCaptchaShow = process.env.PANTHEON_ENVIRONMENT === 'live' ? (process.env.REACT_APP_RECAPTCHA_SHOW_PROD === 'enable') : (process.env.REACT_APP_RECAPTCHA_SHOW_NON_PROD === 'enable');
      if (reCaptchaShow) {
          ReactRecaptcha3.init(process.env.REACT_APP_RECAPTCHA_SITE_KEY).then(status => {
        })
      }
    }, []);

  return (
    <FormContext.Provider
      value={{ activeStepIndex, setActiveStepIndex, formData, setFormData, recaptchaScore, setRecaptchaScore, brand, setBrand, country, setCountry, v2, setV2 }}
    >
      <div className="w-screen h-screen flex flex-col items-center justify-start">
        <Component modalComponent={props.modalComponent} {...props} />
      </div>
    </FormContext.Provider>
  );
}

export default App;
