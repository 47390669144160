import { Field, Form, Formik } from "formik";
import React, { useContext, useRef } from "react";
import { Container, FormControlLabel, FormControl, useTheme, Typography, Paper } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import DataLayerService from "utility/dataLayerService";
import { useComponentName } from "hooks";

// Import SVG Icons.
import { ReactComponent as SeniorIcon } from "components/Forms/form-icons/senior_15963a0d3a.svg";
import { ReactComponent as MilitaryIcon } from "components/Forms/form-icons/military_15956ee5f5.svg";
import { ReactComponent as NeitherIcon } from "components/Forms/form-icons/neither_15957a1137.svg";
import { FormContextLHWSMultiStepEstimate } from "./LHWSMultiStepEstimate";
import styled from "@emotion/styled";
import TermAndCondition from "components/atoms/TermAndCondition";
import { PrimaryButton } from "components/atoms/LeafButtons";
import LeafBox from "components/atoms/LeafBox";
import ProgressBar from "components/atoms/ProgressBar";
import ProgressBarWithPercentage from 'components/atoms/ProgressBarWithPercentage';
import { useVersion } from "hooks";
import { scrollToTop } from '../../../../utility/functions';

const CustomerGroupStep = (props) => {
  const location = props.dataSet?.location ? props.dataSet.location : "multi-step-modal-form";
  const form = props.dataSet?.form ? props.dataSet.form : "modal";
  const { setactiveLHWSStepIndex, formData, setFormData } = useContext(FormContextLHWSMultiStepEstimate);
  const product = 'lhws';
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || 'ZipChecker';
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);
  const v2 = useVersion();
  const formRef = useRef(null);

  let groupList = [];

  const handleImageClick = (value, setFieldValue) => {
    if (value !== 'neither') {
      const groupListIndex  = groupList.indexOf(value);
      if (groupListIndex > -1) {
        groupList.splice(groupListIndex, 1);
      }
      else {
        groupList.push(value);
      }
      setFieldValue("groupLevel", groupList);
    }
    else {
      groupList.splice(0, groupList.length);
      setFieldValue("groupLevel", value);
    }

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const handleOnBlur = (event) => {
    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const StyledFormContainer = styled(Paper)(() => ({
    background: "#ffffff",
    borderRadius: 0,
    maxWidth: isMobile ? "100%" : 520,
    width: "100%",
    height: isMobile ? "auto" : 720,
  }));


  return (
    <Formik
      initialValues={{
        groupLevel: "",
      }}
      onSubmit={(values) => {
        const data = { ...formData, ...values };
        setFormData(data);
        setactiveLHWSStepIndex('details');
        if (v2) {
          scrollToTop(formRef);
        }

        if (userJourneyTracking) {
          let stepNumber = 2;
          if ((componentName === 'ZipChecker') || (componentName === 'ModalForm')) {
            stepNumber = 3;
          }
          // Passing lf.form.status event to DataLayer.
          DataLayerService.formStatusEvent(formName, subformName, product, stepNumber, 1, 'notcompleted');
        }

        // Passing lf.form.start event to DataLayer.
        DataLayerService.formStartEvent(form, location, data);
      }}
    >
      {({ handleSubmit, setFieldValue, values, isSubmitting }) => (
        <Container maxWidth={isMobile ? "xs" : "sm"} disableGutters={true}>
          <StyledFormContainer>
              <Form
                className="flex flex-col justify-center items-start"
                onSubmit={handleSubmit}
                ref={formRef}
                onBlur={
                  (event) => {handleOnBlur(event)}
                }
              >
                <div className="step2">
                  <h3 className="form-step-title text-2xl fw-700">
                    Does anyone in your household qualify for an extra 10% senior
                    or military discount?
                  </h3>
                </div>

                <FormControl component="fieldset">
                  <label className="font-medium text-green-600">
                    Which of these applies?{" "}
                  </label>
                  <div className="mt-2 ml-2 mb-2">
                    <Field name="groupLevel">
                      {({ field }) => (
                        <FormControl component="fieldset">
                          <div className="image-wrapper">
                            <FormControlLabel
                             className={`lf-icon-control-label senior ${
                              values.groupLevel.indexOf("senior") >= 0
                                ? "selected-border"
                                : ""
                            }`}
                              value="senior"
                              control={
                                <SeniorIcon
                                  className={`custom-img ${
                                    values.groupLevel.indexOf("senior") >= 0
                                      ? "selected"
                                      : ""
                                  }`}
                                  alt="Senior"
                                  onClick={() =>
                                    handleImageClick("senior", setFieldValue)
                                  }
                                />
                              }
                              label={
                                <Typography
                                  variant="body4"
                                  className={`image-wrapper-label-text ${
                                    values.groupLevel.indexOf("senior")  >= 0
                                      ? "selected"
                                      : ""
                                  }`}
                                >
                                  Senior
                                </Typography>
                              }
                            />
                            <FormControlLabel
                            className={`lf-icon-control-label military ${
                              values.groupLevel.indexOf("military")  >= 0
                                ? "selected-border"
                                : ""
                            }`}
                              value="military"
                              control={
                                <MilitaryIcon
                                  className={`custom-img ${
                                    values.groupLevel.indexOf("military")  >= 0
                                      ? "selected"
                                      : ""
                                  }`}
                                  alt="Military"
                                  onClick={() =>
                                    handleImageClick("military", setFieldValue)
                                  }
                                />
                              }
                              label={
                                <Typography
                                  variant="body4"
                                  className={`image-wrapper-label-text ${
                                    values.groupLevel.indexOf("military")  >= 0
                                      ? "selected"
                                      : ""
                                  }`}
                                >
                                  Military
                                </Typography>
                              }
                            />
                            <FormControlLabel
                             className={`lf-icon-control-label neither ${
                              values.groupLevel === "neither"
                                ? "selected-border"
                                : ""
                            }`}
                              value="neither"
                              control={
                                <NeitherIcon
                                  className={`custom-img ${
                                    values.groupLevel === "neither"
                                      ? "selected"
                                      : ""
                                  }`}
                                  alt="Neither"
                                  onClick={() =>
                                    handleImageClick("neither", setFieldValue)
                                  }
                                />
                              }
                              label={
                                <Typography
                                  variant="body4"
                                  className={`image-wrapper-label-text ${
                                    values.groupLevel === "neither"
                                      ? "selected"
                                      : ""
                                  }`}
                                >
                                  Neither
                                </Typography>
                              }
                            />
                          </div>
                        </FormControl>
                      )}
                    </Field>
                  </div>
                </FormControl>

                <LeafBox style={{
                  marginTop: 2,
                  marginBottom: 3,
                }}>
                  <PrimaryButton
                    type="submit"
                    isSubmitting={isSubmitting}>
                    Next
                  </PrimaryButton>
                </LeafBox>
                { v2 ?
                  <ProgressBarWithPercentage />
                  :
                  <ProgressBar customClass="" />
                }
                <TermAndCondition />
              </Form>
            </StyledFormContainer>
        </Container>
      )}
    </Formik>
  );
}

export default CustomerGroupStep;
