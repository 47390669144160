// Import SVG Icons.
import React from "react";
import { FormControlLabel, Typography } from "@mui/material";
import { useFormikContext } from "formik";

import { ReactComponent as OneStory } from "./form-icons/one-story.svg";
import { ReactComponent as TwoStory } from "./form-icons/two-story.svg";
import { ReactComponent as ThreeStory } from "./form-icons/three-story.svg";
import { ReactComponent as Small } from "./form-icons/1999.svg";
import { ReactComponent as Medium } from "./form-icons/2000.svg";
import { ReactComponent as Large } from "./form-icons/3000.svg";
import { ReactComponent as Senior } from "./form-icons/senior.svg";
import { ReactComponent as Military } from "./form-icons/military.svg";
import { ReactComponent as Neither } from "./form-icons/neither.svg";
import { ReactComponent as Online } from "./form-icons/online.svg";
import { ReactComponent as Tv } from "./form-icons/tv.svg";
import { ReactComponent as Ctv } from "./form-icons/ctv.svg";
import { ReactComponent as Radio } from "./form-icons/radio.svg";
import { ReactComponent as Event } from "./form-icons/event.svg";
import { ReactComponent as PrintAd } from "./form-icons/print-ad.svg";
import { ReactComponent as Postcard } from "./form-icons/postcard.svg";
import { ReactComponent as Nascar } from "./form-icons/nascar.svg";
import { ReactComponent as Youtube } from "./form-icons/youtube.svg";
import { ReactComponent as StairLifts } from "./form-icons/stair-lifts.svg"
import { ReactComponent as WalkIn } from "./form-icons/walkin-store.svg";
import { ReactComponent as ReadyToHire } from "./form-icons/ready-forhire.svg";
import { ReactComponent as Planning } from "./form-icons/planning.svg";
import { ReactComponent as HomeOwner } from "./form-icons/home-owner.svg";
import { ReactComponent as FamilyMember } from "./form-icons/family-member.svg";
import { ReactComponent as CareTaker } from "./form-icons/care-taker.svg";
import { ReactComponent as YesSvg } from "./form-icons/yes.svg";
import { ReactComponent as NoSvg } from "./form-icons/no.svg";

import { ReactComponent as OnlineIconLHWS } from "./form-icons/online_159592eb07.svg";
import { ReactComponent as EventIconLHWS } from "./form-icons/event_15953862a0.svg";
import { ReactComponent as PrintAdIconLHWS } from "./form-icons/print-ad_15961499d0.svg";
import { ReactComponent as PostcardIconLHWS } from "./form-icons/postcard_1596016b91.svg";
import { ReactComponent as RadioIconLHWS } from "./form-icons/icons-LHWS-web_radio-off.svg";
import { ReactComponent as TVIconLHWS } from "./form-icons/lhws-tv.svg";
import { ReactComponent as CtvLHWS } from "./form-icons/lhws-ctv.svg";

const WrapFormControlLabel = ({ keyText, textLabel, ControlIcon, selected }) => {
  return (
    <FormControlLabel
      value={keyText }
      className= {`${keyText} form-icon`}
      control = {ControlIcon}
      label = {
        <Typography
          variant="body4"
          className={`image-wrapper-label-text ${selected ? "selected" : ""}`}
        >
          { textLabel }
        </Typography>
      }
      labelPlacement={"bottom"}
      sx={{
        ml: 0,
        width: "75px",
        textAlign: "center"
      }}
    >
    </FormControlLabel>
  )
}

export const OneStoryIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"oneStory"}
      textLabel={"1 Story"}
      ControlIcon={(<OneStory
        className={`custom-img ${values.homeLevel === "oneStory"
          ? "with-border"
          : ""
          }`}
        alt="oneStory"
        onClick={handleClick}
      />)}
      selected={values.homeLevel === "oneStory"}
    >
    </WrapFormControlLabel>
  )
};

export const TwoStoryIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"twoStory"}
      textLabel={"2 Story"}
      ControlIcon={(<TwoStory
        className={`custom-img ${values.homeLevel === "twoStory"
          ? "with-border"
          : ""
          }`}
        alt="twoStory"
        onClick={handleClick}
      />)}
      selected={values.homeLevel === "twoStory"}
    >
    </WrapFormControlLabel>
  )
};

export const ThreeStoryIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"threeStory"}
      textLabel={"3 Story"}
      ControlIcon={(<ThreeStory
        className={`custom-img ${values.homeLevel === "threeStory"
          ? "with-border"
          : ""
          }`}
        alt="threeStory"
        onClick={handleClick}
      />)}
      selected={values.homeLevel === "twoStory"}
    >
    </WrapFormControlLabel>
  )
};

export const SmallIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"1999"}
      textLabel={"0-1999"}
      ControlIcon={(<Small
        className={`custom-img ${values.squareFootage === "1999"
          ? "with-border"
          : ""
          }`}
        alt="1999"
        onClick={handleClick}
      />)}
      selected={values.squareFootage === "1999"}
    >
    </WrapFormControlLabel>
  )
};

export const MediumIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"2000"}
      textLabel={"2000-2999"}
      ControlIcon={(<Medium
        className={`custom-img ${values.squareFootage === "2000"
          ? "with-border"
          : ""
          }`}
        alt="2000-2999"
        onClick={handleClick}
      />)}
      selected={values.squareFootage === "2000"}
    >
    </WrapFormControlLabel>
  )
};

export const LargeIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"3000"}
      textLabel={"3000+"}
      ControlIcon={(<Large
        className={`custom-img ${values.squareFootage === "3000"
          ? "with-border"
          : ""
          }`}
        alt="3000+"
        onClick={handleClick}
      />)}
      selected={values.squareFootage === "3000"}
    >
    </WrapFormControlLabel>
  )
};

export const SeniorIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"senior"}
      textLabel={"Senior"}
      ControlIcon={(<Senior
        className={`custom-img ${values.groupLevel.indexOf("senior") >= 0
          ? "with-border"
          : ""
          }`}
        alt="Senior"
        onClick={handleClick}
      />)}
      selected={values.groupLevel.indexOf("senior") >= 0}
    >
    </WrapFormControlLabel>
  )
};

export const MilitaryIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"military"}
      textLabel={"Military"}
      ControlIcon={(<Military
        className={`custom-img ${values.groupLevel.indexOf("military") >= 0
          ? "with-border"
          : ""
          }`}
        alt="Military"
        onClick={handleClick}
      />)}
      selected={values.groupLevel.indexOf("military") >= 0}
    >
    </WrapFormControlLabel>
  )
};

export const NeitherIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"neither"}
      textLabel={"Neither"}
      ControlIcon={(<Neither
        className={`custom-img ${values.groupLevel === "neither"
          ? "with-border"
          : ""
          }`}
        alt="Neither"
        onClick={handleClick}
      />)}
      selected={values.groupLevel === "neither"}
    >
    </WrapFormControlLabel>
  )
};

export const OnlineIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"41"}
      textLabel={"Online"}
      ControlIcon={(<Online
        className={`custom-img ${values.adLevel === "41"
          ? "with-border"
          : ""
          }`}
        alt="Online"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "41"}
    >
    </WrapFormControlLabel>
  )
};

export const TvIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"81"}
      textLabel={"TV"}
      ControlIcon={(<Tv
        className={`custom-img ${values.adLevel === "81"
          ? "with-border"
          : ""
          }`}
        alt="TV"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "81"}
    >
    </WrapFormControlLabel>
  )
};

export const CtvIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"41TCTV"}
      textLabel={"TV (streaming)"}
      ControlIcon={(<Ctv
        className={`custom-img ${values.adLevel === "41TCTV"
          ? "with-border"
          : ""
          }`}
        alt="TV (streaming)"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "41TCTV"}
    >
    </WrapFormControlLabel>
  )
};

export const RadioIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"91"}
      textLabel={"Radio"}
      ControlIcon={(<Radio
        className={`custom-img ${values.adLevel === "91"
          ? "with-border"
          : ""
          }`}
        alt="Radio"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "91"}
    >
    </WrapFormControlLabel>
  )
};

export const EventIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"99w"}
      textLabel={"Event"}
      ControlIcon={(<Event
        className={`custom-img ${values.adLevel === "99w"
          ? "with-border"
          : ""
          }`}
        alt="Event"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "99w"}
    >
    </WrapFormControlLabel>
  )
};

export const PrintAdIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"88w"}
      textLabel={"Print Ad"}
      ControlIcon={(<PrintAd
        className={`custom-img ${values.adLevel === "88w"
          ? "with-border"
          : ""
          }`}
        alt="Print Ad"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "88w"}
    >
    </WrapFormControlLabel>
  )
};

export const PostcardIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"10w"}
      textLabel={"Postcard"}
      ControlIcon={(<Postcard
        className={`custom-img ${values.adLevel === "10w"
          ? "with-border"
          : ""
          }`}
        alt="Postcard"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "10w"}
    >
    </WrapFormControlLabel>
  )
};

export const NascarIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"83"}
      textLabel={"NASCAR"}
      ControlIcon={(<Nascar
        className={`custom-img ${values.adLevel === "83"
          ? "with-border"
          : ""
          }`}
        alt="NASCAR"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "83"}
    >
    </WrapFormControlLabel>
  )
};

export const YoutubeIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"41PAV"}
      textLabel={"Youtube"}
      ControlIcon={(<Youtube
        className={`custom-img ${values.adLevel === "41PAV"
          ? "with-border"
          : ""
          }`}
        alt="Youtube"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "41PAV"}
    >
    </WrapFormControlLabel>
  )
};

export const StairLiftsIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"Stair Lifts"}
      textLabel={"Stair Lifts"}
      ControlIcon={(<StairLifts
        className={`custom-img ${values.product.indexOf("Stair Lifts") >= 0
          ? "with-border"
          : ""
          }`}
        alt="Stair Lifts"
        onClick={handleClick}
      />)}
      selected={values.product.indexOf("Stair Lifts") >= 0}
    >
    </WrapFormControlLabel>
  )
};

export const WalkInIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"Tub or Shower"}
      textLabel={"Walk-In Shower or Bath"}
      ControlIcon={(<WalkIn
        className={`custom-img ${values.product.indexOf("Tub or Shower") >= 0
          ? "with-border"
          : ""
          }`}
        alt="Walk-In Shower or Bath"
        onClick={handleClick}
      />)}
      selected={values.product.indexOf("Tub or Shower") >= 0}
    >
    </WrapFormControlLabel>
  )
};

export const ReadyToHireIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={1}
      textLabel={"Ready to Hire"}
      ControlIcon={(<ReadyToHire
        className={`custom-img ${values.projectStatus === 1
          ? "with-border"
          : ""
          }`}
        alt="Ready to Hire"
        onClick={handleClick}
      />)}
      selected={values.projectStatus === 1}
    >
    </WrapFormControlLabel>
  )
};

export const PlanningIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={2}
      textLabel={"Planning & Budgeting"}
      ControlIcon={(<Planning
        className={`custom-img ${values.projectStatus === 2
          ? "with-border"
          : ""
          }`}
        alt="Planning & Budgeting"
        onClick={handleClick}
      />)}
      selected={values.projectStatus === 2}
    >
    </WrapFormControlLabel>
  )
};

export const HomeOwnerIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"homeOwner"}
      textLabel={"Homeowner"}
      ControlIcon={(<HomeOwner
        className={`custom-img ${values.roleType === "homeOwner"
          ? "with-border"
          : ""
          }`}
        alt="Homeowner"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "homeOwner"}
    >
    </WrapFormControlLabel>
  )
};

export const FamilyMemberIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"familyMember"}
      textLabel={"Family Owner"}
      ControlIcon={(<FamilyMember
        className={`custom-img ${values.roleType === "familyMember"
          ? "with-border"
          : ""
          }`}
        alt="Family Owner"
        onClick={handleClick}
      />)}
      selected={values.roleType === "familyMember"}
    >
    </WrapFormControlLabel>
  )
};

export const CareTakerIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"careTaker"}
      textLabel={"Care Taker"}
      ControlIcon={(<CareTaker
        className={`custom-img ${values.roleType === "careTaker"
          ? "with-border"
          : ""
          }`}
        alt="Care Taker"
        onClick={handleClick}
      />)}
      selected={values.roleType === "careTaker"}
    >
    </WrapFormControlLabel>
  )
};

export const YesIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"yes"}
      textLabel={"Yes"}
      ControlIcon={(<YesSvg
        className={`custom-img ${values.financing === "yes"
          ? "with-border"
          : ""
          }`}
        alt="Yes"
        onClick={handleClick}
      />)}
      selected={values.financing === "yes"}
    >
    </WrapFormControlLabel>
  )
};

export const NoIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"no"}
      textLabel={"No"}
      ControlIcon={(<NoSvg
        className={`custom-img ${values.financing === "no"
          ? "with-border"
          : ""
          }`}
        alt="No"
        onClick={handleClick}
      />)}
      selected={values.financing === "no"}
    >
    </WrapFormControlLabel>
  )
};

export const LHWSOnlineIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={`41 lf-icon-control-label ${values.adLevel === "41"
        ? "selected-border"
        : ""
        }`}
      textLabel={"Online"}
      ControlIcon={(<OnlineIconLHWS
        className={`custom-img ad-level-img ${values.adLevel === "41"
          ? "selected"
          : ""
          }`}
        alt="Online"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "41"}
    >
    </WrapFormControlLabel>
  )
};

export const LHWSEventIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={`99w lf-icon-control-label ${values.adLevel === "99w"
        ? "selected-border"
        : ""
        }`}
      textLabel={"Event"}
      ControlIcon={(<EventIconLHWS
        className={`custom-img ad-level-img ${values.adLevel === "99w"
          ? "selected"
          : ""
          }`}
        alt="Event"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "99w"}
    >
    </WrapFormControlLabel>
  )
};

export const LHWSPrintAdIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={`88w lf-icon-control-label ${values.adLevel === "88w"
        ? "selected-border"
        : ""
        }`}
      textLabel={"Print Ad"}
      ControlIcon={(<PrintAdIconLHWS
        className={`custom-img ad-level-img ${values.adLevel === "88w"
          ? "selected"
          : ""
          }`}
        alt="Print Ad"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "88w"}
    >
    </WrapFormControlLabel>
  )
};

export const LHWSPostcardIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={`10w lf-icon-control-label ${values.adLevel === "10w"
        ? "selected-border"
        : ""
        }`}
      textLabel={"PostCard"}
      ControlIcon={(<PostcardIconLHWS
        className={`custom-img ad-level-img ${values.adLevel === "10w"
          ? "selected"
          : ""
          }`}
        alt="PostCard"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "10w"}
    >
    </WrapFormControlLabel>
  )
};

export const LHWSRadioIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={`91 lf-icon-control-label ${values.adLevel === "91"
        ? "selected-border"
        : ""
        }`}
      textLabel={"Radio"}
      ControlIcon={(<RadioIconLHWS
        className={`custom-img ad-level-img ${values.adLevel === "91"
          ? "selected"
          : ""
          }`}
        alt="Radio"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "91"}
    >
    </WrapFormControlLabel>
  )
};

export const LHWSTVIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={`81 lf-icon-control-label ${values.adLevel === "81"
        ? "selected-border"
        : ""
        }`}
      textLabel={"TV"}
      ControlIcon={(<TVIconLHWS
        className={`custom-img ad-level-img ${values.adLevel === "81"
          ? "selected"
          : ""
          }`}
        alt="TV"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "81"}
    >
    </WrapFormControlLabel>
  )
};

export const LHWSCTVIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={`41TCTV lf-icon-control-label ${values.adLevel === "41TCTV"
        ? "selected-border"
        : ""
        }`}
      textLabel={"TV (streaming)"}
      ControlIcon={(<CtvLHWS
        className={`custom-img ad-level-img ${values.adLevel === "41TCTV"
          ? "selected"
          : ""
          }`}
        alt="TV (streaming)"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "41TCTV"}
    >
    </WrapFormControlLabel>
  )
};
