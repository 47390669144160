import React from "react";
import { Container, Paper, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/system";
import { TertiaryButton } from "components/atoms/LeafButtons";
import TermAndCondition from "components/atoms/TermAndCondition";
import AlertMessage from "components/atoms/AlertMessage";
import { useComponentName } from "hooks";
import DataLayerService from "utility/dataLayerService";

// Import SVG Icons.
import { ReactComponent as PhoneIcon } from "components/Forms/form-icons/phone-icon.svg";
import { ReactComponent as CheckIcon } from "components/Forms/form-icons/tick-lhws.svg";

const FinalStep = (props) => {
  const phoneNumber = '1-888-701-5497';
  const telPhoneNumber = phoneNumber.replace('-', '');
  const product = 'lhws';
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || 'ZipChecker';
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);

  const handleCallNow = () => {
    window.location.href = "tel:+" + telPhoneNumber;
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const StyledFormContainer = styled(Paper)(({ theme }) => ({
    background: "#ffffff",
    margin: `${theme.spacing(6)} ${isMobile ? theme.spacing(2) : theme.spacing(6)}`,
    padding: `${theme.spacing(2)} ${isMobile ? theme.spacing(2) : theme.spacing(6)}`,
    borderRadius: 8,
    width: isMobile ? "100%": 520,
  }));

  if (userJourneyTracking) {
    let stepNumber = 1;
    if ((componentName === 'ZipChecker') || (componentName === 'ModalForm')) {
      stepNumber = 2;
    }
    // Passing lf.form.status event to DataLayer.
    DataLayerService.formStatusEvent(formName, subformName, product, stepNumber, 5, 'completed');
  }

  const StyledDivider = styled(Divider)`
    margin: 16px auto;
    width: 90%;
    border: 2px solid #02a780;
    opacity: 1;
  `;

  return (
    <Container maxWidth={isMobile ? "xs" : "sm"}>
      <StyledFormContainer>
        <div className="thank-you">
          <AlertMessage message={'Thank you! Your information has been received.'} severity={'success'} />
          <h3 className="fw-bold">We'll be calling you soon to schedule your free water test and consultation.</h3>

          <p> Our water specialists will make recommendations for improving your water and provide you with a free, no-obligation quote. </p>

          <ul>
            <li className="vertical-list__item">
              <CheckIcon
                alt="Check"
              />
              <span> Our specialists will make recommendations for improving your water and provide you with a free, no-obligation quote. </span>
            </li>
            <li className="vertical-list__item">
              <CheckIcon
                alt="Check"
              />
              <span> We are committed to you safety. <a href="https://www.leafhomewatersolutions.com/covid">Learn about our COVID-19 protocol.</a>
              </span>
            </li>
          </ul>

          <StyledDivider />
          <p className="step5-sub-text text-center">
            Can't wait to schedule your free estimate? <br />
            Give us a call at{" "}
            <span className="phone-number" onClick={handleCallNow}>{`${phoneNumber}`}</span>
          </p>
          <TertiaryButton
            handleClick={handleCallNow}
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "auto",
              marginRight: "auto"
            }}
            type="submit"
          >
            <PhoneIcon className="phone-icon"></PhoneIcon>
            <span style={{ marginLeft: "0.5rem" }}>Call Now</span>
          </TertiaryButton>
          <br />

          <TermAndCondition />
        </div>
      </StyledFormContainer>
    </Container>
  );
}
export default FinalStep;
