import { Form, Formik } from "formik";
import React, { useContext } from "react";
import { IReferredContext } from "./IReferred";
import { Container } from "@mui/material";
import * as yup from "yup";
import { useZipCodeValidation } from "utility/validation";
import ZipCodeInput from "components/atoms/ZipcodeInput";
import EmailInput from "components/atoms/EmailInput";
import PhoneNumberInput from "components/atoms/PhoneNumberInput";
import TextInput from "components/atoms/TextInput";
import Image from './referral/1200x1200-leaffilter-summer.jpg';

// Import SVG Icons.
import { ReactComponent as CheckIcon } from "components/Forms/form-icons/check.svg";
import { PrimaryButton } from "components/atoms/LeafButtons";

const ReferralDetailsStep = (props) => {
  const { setActiveIRStep, formData, setFormData } =
    useContext(IReferredContext);
  const LeafImage = Image;

  // Zipcode Validation based on the Country Code.
  const zipCodeValidation = useZipCodeValidation();

  const ValidationSchema = yup.object().shape({
    name: yup
      .string()
      .trim()
      .matches(/[^\s\d]+ .+$/, "Full Name must include both First Name and Last Name.")
      .required('Full Name is required.'),
    address: yup.string().required("Address is required."),
    city: yup.string().required("City is required."),
    state: yup.string().required("State is required."),
    zip: yup
      .string()
      .matches(zipCodeValidation.validationPattern, zipCodeValidation.validationMessage)
      .required(`${zipCodeValidation.zipCodeText} is required.`),
    email: yup
      .string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Email Address is not valid.')
      .required("Email Address is required."),
    phone: yup.string().required("Phone is required."),
  });

  return (
    <Formik
      initialValues={{
        name: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        email: '',
        phone: '',
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values) => {
        const data = { ...formData, ...values };
        setFormData(data);
        setActiveIRStep('jobdetails');
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Container disableGutters={true}>
          <h1 className="section-header h2"> Thanks for your interest in LeafFilter! </h1>
          <Form
            className="flex flex-col justify-center items-start referral__container--fluid"
            onSubmit={handleSubmit}
          >
            <div className="step2 thank-you-interest">
              <div className="image-content">
                <img className="profile-photo" src={LeafImage} alt={"A gutter system after leaf filter was installed during the summer"}/>
                <p className="image-content__copy">LeafFilter Gutter Protection is scientifically designed to keep everything out of your gutters -- except for water.</p>
                <ul>
                  <li className="vertical-list__item">
                    <CheckIcon
                      alt="Check"
                    />
                    No more cleaning out your gutters
                  </li>
                  <li className="vertical-list__item">
                    <CheckIcon
                      alt="Check"
                    />
                    Professionally Installed
                  </li>
                  <li className="vertical-list__item">
                    <CheckIcon
                      alt="Check"
                    />
                    Installs on your existing gutters
                  </li>
                  <li className="vertical-list__item">
                    <CheckIcon
                      alt="Check"
                    />
                    Lifetime, transferable warranty
                  </li>
                </ul>
              </div>

              <div className="step-2-form">
                <h4 className="heading thank-you-interest__form-header">Please tell us who referred you</h4>
                <TextInput fieldName={'name'} label={'Full Name'} placeholder={"E.g., John Smith"} />
                <TextInput fieldName={'address'} label={'Address'} placeholder={'Address'} />
                <TextInput fieldName={'city'} label={'City'} placeholder={'City'} />
                <TextInput fieldName={'state'} label={'State'} placeholder={'State'} />
                <ZipCodeInput fieldName={'zip'} />
                <EmailInput fieldName={'email'} />
                <PhoneNumberInput fieldName={'phone'} />
                <PrimaryButton type="submit" isSubmitting={isSubmitting}>
                  Next
                </PrimaryButton>
              </div>
            </div>
          </Form>
        </Container>
      )}
    </Formik>
  );
}

export default ReferralDetailsStep;
