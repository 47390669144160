import { Form, Formik } from "formik";
import React, {  useContext, useRef } from "react";
import { Container, Paper, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/system";
import { useGetPhoneNumber } from "utility/countryOverride";
import TermAndCondition from "components/atoms/TermAndCondition";
import { ReactComponent as PhoneIcon } from "components/Forms/form-icons/phone-icon.svg";
import { SecondaryButton } from "components/atoms/LeafButtons";
import { FormContext } from "../../../App";
import AlertMessage from "components/atoms/AlertMessage";
import SelfSchedulerScript from "components/atoms/SelfSchedulerScript";
import { useProduct, useComponentName } from "hooks";
import DataLayerService from "utility/dataLayerService";

const ThankYou = (props) => {

  const phoneNumber = useGetPhoneNumber();
  const telPhoneNumber = phoneNumber.replace('-', '');
  const { formData } = useContext(FormContext);
  const zipCode = formData.zipCode;
  const guid = formData.guid;
  const vertical = 'LF';
  const showProtocolRef = useRef(null);
  const product = useProduct();
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || 'ZipChecker';
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);

  const showProtocolInfo = showProtocolRef.current && showProtocolRef.current.style.display === 'block';

  const handleCallNow = () => {
    window.location.href = "tel:+" + telPhoneNumber;
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const StyledFormContainer = styled(Paper)(({ theme }) => ({
    background: "#ffffff",
    padding: `${theme.spacing(2)} ${isMobile ? theme.spacing(2) : theme.spacing(6)}`,
    borderRadius: 8,
    width:"100%",
    height: showProtocolInfo ? 650  : (isMobile ? "auto" : 580),
  }));

  if (userJourneyTracking) {
    // Update the StepNumber for ShortLeadform.
    let stepNumber = 4;
    if ((componentName === 'ShortLeadForm') || (props.modalComponent === 'ShortLeadForm')) {
      stepNumber = 2;
      if ((componentName === 'ZipChecker') || (componentName === 'ModalForm')) {
        stepNumber = 3;
      }
    }
    else {
      if ((componentName === 'ZipChecker') || (componentName === 'ModalForm')) {
        stepNumber = 5;
      }
    }

    // Number of required Fields.
    let requiredFields = 4;
    if (product === 'ca') {
      requiredFields = 5;
    }

    // Passing lf.form.status event to DataLayer.
    DataLayerService.formStatusEvent(formName, subformName, product, stepNumber, requiredFields, 'completed');
  }

  const toggleProtocolInfo = () => {
    if (showProtocolRef.current) {
      if (showProtocolRef.current.style.display === 'none') {
        showProtocolRef.current.style.display = 'block';
      } else {
        showProtocolRef.current.style.display = 'none';
      }
    }
  };

  return (
    <>
      <SelfSchedulerScript zipCode={zipCode} guid={guid} verticalID={vertical} />

      <Formik
        initialValues={{
        }}
      >
        {() => (
          <Container maxWidth={isMobile ? "xs" : "sm"} disableGutters={true}>
            <StyledFormContainer>
              <Form
                className="flex flex-col justify-center items-start"
              >
                <AlertMessage message={'Your information has been received!'} severity={'success'} />

                <div className="step5-main-text">
                  We'll be calling you soon to <br /> schedule a FREE estimate.
                </div>
                <br />

                <p className="step5-sub-text">
                  Our estimator will inspect and measure your gutters, discuss
                  your needs, and provide you with a custom, no-obligation quote.
                </p>
                <br />

                <p className="step5-sub-text">
                  We are committed to your safety.{" "}
                  <u onClick={toggleProtocolInfo}>
                    Learn about our COVID-19 protocol.
                  </u>
                </p>
                  <ul ref={showProtocolRef} style={{ display: 'none'}}>
                    <li>Our team limits contact, including handshakes.</li>
                    <li>
                      We frequently sanitize and encourage regular washing of
                      hands.
                    </li>
                    <li>
                      We practice social distancing and require team members to
                      wear masks.
                    </li>
                  </ul>

                <div id="self-scheduler"></div>


                <Divider className="divider" />
                <p className="step5-sub-text">
                  Can't wait to schedule your free estimate? <br />
                  Give us a call at{" "}
                  <span className="phone-number">{`${phoneNumber}`}</span>
                </p>
                <SecondaryButton
                  handleClick={handleCallNow}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "auto",
                    marginRight: "auto"
                  }}
                  type="submit"
                >
                  <PhoneIcon className="phone-icon"></PhoneIcon>
                  <span style={{ marginLeft: "0.5rem" }}>Call Now</span>
                </SecondaryButton>
                <TermAndCondition />
              </Form>
            </StyledFormContainer>
          </Container>
        )}
      </Formik>
    </>
  );
}

export default ThankYou;
