import { Field, useFormikContext } from 'formik';
import React from "react";
import { useZipCodeValidation } from "utility/validation";
import LeafBox from 'components/atoms/LeafBox';
import ValidationIcon from "components/atoms/ValidationIcon";
import { useVersion } from "../../../hooks";
import InputMask from "react-input-mask";

const ZipCodeInput = ({fieldName, style = {}, boxStyle = {}, label = true }) => {
  const { errors, touched } = useFormikContext();
  const zipCodeValidation = useZipCodeValidation();
  const v2 = useVersion();
  let inputStyle = {
    width: '100%',
    borderRadius: '3px',
    height: '40px',
    border: '1px solid #d1d1d1',
    padding: '5px 10px',
  };

  inputStyle = {
    ...inputStyle,
    ...style
  }

  return (
    <LeafBox style={boxStyle}>
      {label ? (<label> {zipCodeValidation.zipCodeText}
        {v2 ? (<span className="required--asterisk text-danger"> * </span>) : ''}</label> )
        : ""
      }
      {v2 ?
        <Field name={fieldName}>
          {({ field }) => (
            <InputMask
              {...field}
              className={`input-field ${
                touched[fieldName] && errors[fieldName]
                  ? "error"
                  : touched[fieldName]
                  ? "correct"
                  : ""
              }`}
              mask={ '99999' }
              maskChar="_"
              style={inputStyle}
              formatChars= {{
                '9': '[0-9]',
              }}
            />
          )}
        </Field>
      :
        <Field
          className={`input-field ${
            touched[fieldName] && errors[fieldName]
              ? "error"
              : touched[fieldName]
              ? "correct"
              : ""
          }`}
          name={fieldName}
          type="text"
          placeholder={zipCodeValidation.zipCodePlaceHolder}
          style={inputStyle}
        />
      }
      <ValidationIcon fieldName={fieldName} touched={touched} errors={errors} />
    </LeafBox>
  );
}

export default ZipCodeInput;
